.AppDateTimePicker {
    border: 1px solid #C3C3C3;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 7px;
    color: #C3C3C3;
    &.outer {
        height: 40px !important;
        // margin-left: 8px !important;
    }

    &>.calender-outer {
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translate(-50%, 10%);
    }
}