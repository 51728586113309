.react-dropdown-select-dropdown {
    min-width: 100%;
    top: 100% !important;
    overflow-x: hidden !important;
    z-index: 11 !important;
}

.react-dropdown-select[disabled],
.ant-upload-disabled {
    opacity: 0.8;
    background-color: #e9ecef;
}

.react-dropdown-select[disabled] .ant-btn-default,
.react-dropdown-select[disabled] .ant-btn-default:hover,
.ant-upload-disabled .ant-btn-default,
.ant-upload-disabled .ant-btn-default:hover {
    background-color: #e9ecef;
}

.react-dropdown-select {
    width: 100% !important;
    height: 40px !important;
    border-radius: 4px !important;
    background: white;

    .react-dropdown-select-input {
        font-size: 13px;
    }
}

.react-dropdown-select[disabled],
.ant-upload-disabled {
    opacity: 0.8;
    background-color: #e9ecef;
}

.react-dropdown-select[disabled] .ant-btn-default,
.react-dropdown-select[disabled] .ant-btn-default:hover,
.ant-upload-disabled .ant-btn-default,
.ant-upload-disabled .ant-btn-default:hover {
    background-color: #e9ecef;
}


.react-dropdown-select-content {
    &>span {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 230px;
        height: 1.3em;
        white-space: nowrap;
    }
}