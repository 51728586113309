.AppTable-Component {
    table {
        color: #343333;
        font-family: "Poppins", sans-serif;

        thead {
            tr {
                th {
                    color: #343333;
                    font-family: "Poppins", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    background-color: #F1F4F8 !important;
                }
            }
        }

        .dropdown-item {
            i {
                width: 1rem;
                margin-right: 0.5rem;
            }
        }

        .ant-table-tbody>tr>td {
            padding: 14px !important;
        }

        // .ant-table-cell:last-child { 
        //     text-align: end;
        // }

        .ant-checkbox.ant-checkbox-checked {
            &:hover {
                .ant-checkbox-inner {
                    background-color: var(--primary) !important;
                    border-color: var(--primary) !important;
                }
            }
        }

        .ant-checkbox-inner {
            width: 20px;
            height: 20px;
        }

        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: var(--primary);
                border-color: var(--primary);
            }
        }

        .ant-checkbox.ant-wave-target {
            &:hover {
                .ant-checkbox-inner {
                    border-color: var(--primary);
                }
            }
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner:after {
            background-color: var(--primary);
        }

        .ant-table-row.ant-table-row-selected>.ant-table-cell {
            background-color: #F1F4F8 !important;
        }

        .ant-table-row.ant-table-cell-row-hover {
            background-color: #F1F4F8 !important;
        }

        .status-dropdown {

            &.no-arrow {
                .dropdown-toggle {
                    &>i {
                        display: none;
                    }
                }
            }

            .dropdown-toggle {
                display: flex !important;
                align-items: center;
                justify-content: space-between;
                max-width: max-content;


                // &>span {
                //     display: flex;
                //     align-items: center;
                //     justify-content: space-between;

                //     &::after {
                //         display: block;
                //         content: "\f078";
                //         font-family: 'Font Awesome 6 Free';
                //         font-weight: 900;
                //     }
                // }

                cursor: pointer;
                // background-color: rgba($purple, 0.1) !important;
                // color: $purple !important;
                padding: 8px 14px;
                font-size: 14px;
                font-weight: 500;
                border: 0 !important;
                border-radius: 0.25rem;
                text-transform: capitalize;

                &.success {
                    background-color: $success-light !important;
                    color: $success !important;
                }

                &.warning {
                    background-color: $warning-light !important;
                    color: $warning !important;
                }

                &.error {
                    background-color: $danger-light !important;
                    color: $danger !important;
                }
            }

            .status-drop-menu {
                min-width: 4rem;

                .dropdown-item {
                    padding: 0.3rem 1.0rem;
                }
            }

            &.status-dropdown {
                .dropdown-toggle {
                    display: inline-block;

                    &.Testing {
                        background-color: $warning-light !important;
                        color: $warning !important;
                    }

                    &.Complete,
                    .interested {
                        background-color: $success-light !important;
                        color: $success !important;
                    }

                    &.Progress {
                        background-color: rgba(187, 107, 217, 0.1) !important;
                        color: $purple !important;
                    }

                    &.Pending {
                        background-color: $danger-light !important;
                        color: $danger !important;
                    }

                    &.Low {
                        background-color: $danger-light !important;
                        color: $danger !important;
                    }

                    &.Hight {
                        background-color: $success-light !important;
                        color: $success !important;
                    }

                    &.Medium {
                        background-color: rgba(44, 44, 44, 0.1) !important;
                        color: #888888 !important;
                    }
                }
            }
        }
    }
}