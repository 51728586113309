body>* {

    scrollbar-color: var(--primary) !important;

    // /* width */
    // ::-webkit-scrollbar {
    //     width: 20px !important;
    // }

    // /* Track */
    // ::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 5px grey !important;
    //     border-radius: 10px !important;
    // }

    // /* Handle */
    // ::-webkit-scrollbar-thumb {
    //     background: red !important;
    //     border-radius: 10px !important;
    // }

    // /* Handle on hover */
    // ::-webkit-scrollbar-thumb:hover {
    //     background: #b30000 !important;
    // }
}

.logo_header {
    img {
        width: 300px;
    }
}

.mw-50 {
    max-width: 50px;
}

.mw-75 {
    max-width: 75px;
}

@media screen and (min-width: 1200px) {
    .mw-lg-50 {
        max-width: 50px;
    }

    .mw-lg-75 {
        max-width: 75px;
    }

    .mw-lg-100 {
        max-width: 100px;
    }

    .mw-lg-150 {
        max-width: 150px;
    }
}

@media screen and (min-width: 992px) {
    .mw-md-50 {
        max-width: 50px;
    }

    .mw-md-75 {
        max-width: 75px;
    }
}

@media screen and (min-width: 1510px) {

    .leads_wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        padding-top: 0;
    }

}

@media screen and (min-width: 1410px) {

    .application_wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        padding-top: 0;
    }

}

@media screen and (min-width: 1700px) {

    .overallleads_search_wrapper {
        position: absolute;
        top: 2rem;
        right: 1rem;
        width: 100%;
        padding-top: 0;
    }

}

@media screen and (min-width: 1200px) {

    .kycrequest_search_wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        padding-top: 0;
    }

}

.filter-row {
    &.overallleads_search_wrapper {
        .row {
            margin: 1rem !important;
        }
    }

    @media screen and (min-width: 1400px) {

        &.pending_search_wrapper,
        &.overallleads_search_wrapper {
            .row {
                [class*='col-'].input_column {
                    max-width: 180px;
                }
            }
        }

        &.leads_search,
        &.application_search {
            .row {
                justify-content: end !important;
            }

            [class*='col-'].input_column {
                max-width: 180px;
            }

        }

    }

    @media screen and (min-width: 1200px) {
        &.digital_leads_search {
            &.not_admin {
                .row {
                    justify-content: space-between;

                    [class*='col-'] {
                        padding: 0;

                        .apply-now-btn {
                            min-width: 100px;
                            white-space: nowrap;
                            padding: 0.719rem 0;
                        }

                        .grey_action_btn {
                            min-width: 80px;
                        }
                    }
                }
            }
        }

        &.digi_team_member_search {
            .row {
                justify-content: space-between;

                [class*='col-'] {
                    padding: 0;

                    .add_digi_team_member_btn {
                        white-space: nowrap;
                        padding: 0.719rem;
                    }
                }
            }

        }

        &.leads_search,
        &.application_search {
            .header_box_column {
                max-width: 300px;
            }
        }

        &.overallleads_search_wrapper {
            .input_column {
                &.applicantName {
                    max-width: 220px !important;
                    flex: 0 0 auto;
                    width: 25%;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {

        &.leads_search,
        &.application_search {
            .row {
                justify-content: flex-start;
            }
        }

        .row {
            justify-content: end;
        }
    }
}

.text-primary {
    &:active {
        color: var(--primary) !important;
    }
}

.heading_title {
    color: #234C9B;
    font-weight: 600;
}

.separator {
    margin: 0 10px;
    width: 2px;
    height: 40px;
    background-color: var(--primary);
    color: var(--primary);
}

.offcanvas {
    .modal-title {
        color: #343333;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
    }
}

.dropdown-toggle::after {
    display: none !important;
}

.leads_card {
    img {
        width: 42px;
    }
}

.text_change_btn {
    &:hover:before {
        content: "Enquire Now";
    }

    &:hover span {
        display: none
    }
}

// HIDE ARROWS IN NUMBER INPUT 
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.product_card {
    padding: 20px;
    border-radius: 10px;
    // max-width: 300px;
    border: 1px solid #DCDCDC;
    background: #FFF;
    box-shadow: 4px 4px 4px 0px rgba(226, 226, 226, 0.25);
    cursor: pointer;

    img {
        width: clamp(60px, 4vw, 80px);
        height: clamp(60px, 4vw, 80px);
        border-radius: 100px;
        border: 3px solid #fff;
        transition: .9s;
        object-fit: cover;
    }

    .content {
        .btn {
            max-width: 160px;

            @media screen and (max-width: 1100px) {
                max-width: 140px;
            }
        }
    }

    &:hover img {
        box-shadow: 0 0 0 3px var(--primary);
    }
}

.product_card:hover {
    .content {
        h4 {
            color: #234C9B;
        }
    }
}

.info_card {
    // margin: 0 20px;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 10px;
    background: var(--primary);

    @media screen and (max-width: 575px) {
        margin-bottom: 14px;
    }

    .content {
        display: flex;
        position: relative;

        .edit_btn {
            width: 36px;
            height: 36px;
            border: 0;
            background: transparent;
            color: white;
            font-size: 16px;
            position: absolute;
            top: 0;
            right: 0;
            border: 1px solid transparent;
            border-radius: 8px;
            cursor: pointer;
            transition: .3s;

            &:hover {
                border: 1px solid #fff;
            }
        }

        .mini_box {
            margin: 0;
            width: 100%;
            max-width: 300px;
            display: flex;
            align-items: flex-start;
            color: #FFF;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            position: relative;

            i,
            img {
                margin: 0;
                margin-right: 1rem;
                font-size: 18px;
            }

            &:not(.end)::after {
                content: "";
                display: block;
                width: 2px;
                height: 100%;
                background-color: #ffffff52;
                position: absolute;
                right: 25%;
            }

            img {
                margin: 0;
                margin-right: 0.8rem;
                width: 20px;
                height: 20px;
                object-fit: contain;
            }

            p {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .info_card .content {
        flex-wrap: wrap;
    }

    .info_card .content .mini_box:not(.end)::after {
        display: none;
    }

    .info_card .content .mini_box {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 992px) {
    .info_card .content .edit_btn {
        top: -50px;
    }
}

.profile_card {
    // margin: 0 20px;
    margin-bottom: 30px;
    padding: 20px 10px;
    // 
    background-color: #F8FAFF !important;
    border: 1px solid #D6DBE7 !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    outline: 0 !important;

    .card-header {
        .title {
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0;
        }

        .action_box {
            button {
                border: 0;
                background: transparent;
                color: #234C9B;
                font-size: 18px;
                width: 36px;
                height: 36px;
                transition: 0.3s;

                &:hover {
                    color: var(--primary);
                }
            }

            .separator {
                color: var(--primary);
                background-color: var(--primary);
            }

            .check_icon {
                width: 28px;
                height: 28px;
                background: var(--primary);
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                border-radius: 100px;

            }
        }
    }

    .card-body {
        h5 {
            color: #6E7D9C;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }

        p {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }
    }
}

.profile-canvas {
    .modal-title {
        max-width: 190px;
    }
}

// PROFILE STEPPER
#customStepper {
    padding: 70px 0 40px;
    border-radius: 14px;
    background: var(--primary);
    overflow-x: scroll;


    .nav-link {
        position: relative;

        &.completed,
        &.active {
            &[stepclassname="aadhar"] {
                &::before {
                    content: url('~/src/images/avatar/aadhar_card_icon_light.png');
                }
            }

            &[stepclassname="pan"] {
                &::before {
                    content: url('~/src/images/avatar/pan_card_icon_light.png');
                }
            }

            &[stepclassname="account"] {
                &::before {
                    content: url('~/src/images/avatar/account_details_icon_light.png');
                }
            }

            &[stepclassname="docsupload"] {
                &::before {
                    content: url('~/src/images/avatar/docs_upload_icon_light.png');
                }
            }
        }

        &[stepclassname="mobile"] {
            &.completed {
                content: url('~/src/images/avatar/mobile_icon.png');
            }

            &::before {
                content: url('~/src/images/avatar/mobile_icon.png');
                position: absolute;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &[stepclassname="aadhar"] {

            &::before {
                content: url('~/src/images/avatar/aadhar_card_icon.png');
                position: absolute;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &[stepclassname="pan"] {
            &.completed {
                content: url('~/src/images/avatar/pan_card_icon_light.png');
            }

            &::before {
                content: url('~/src/images/avatar/pan_card_icon.png');
                position: absolute;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &[stepclassname="account"] {
            &.completed {
                content: url('~/src/images/avatar/account_details_icon_light.png');
            }

            &::before {
                content: url('~/src/images/avatar/account_details_icon.png');
                position: absolute;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &[stepclassname="docsupload"] {
            &.completed {
                content: url('~/src/images/avatar/docs_upload_icon_light.png');
            }

            &::before {
                content: url('~/src/images/avatar/docs_upload_icon.png');
                position: absolute;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
            }
        }


        &::after {
            background-color: #FFFFFF38 !important;
        }

        &+#RFS-LabelContainer {
            color: #FFFFFF82;
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;

            span {
                word-spacing: 100px;
            }
        }

        &.active {
            &::after {
                background-color: #FFFFFF38 !important;
            }

            &+#RFS-LabelContainer {
                color: #fff;
            }

            span {
                background: white;
                border-color: white;
                box-shadow: 0 0 0 3px #fff;
            }
        }

        &.completed {
            &::after {
                background-color: #fff !important;
            }

            &+#RFS-LabelContainer {
                color: #fff;
            }

            span {
                background: white;
                border-color: white;
                box-shadow: 0 0 0 3px #fff;
            }
        }

        span {
            width: 20px;
            height: 20px;
            color: #fff;
            background: var(--primary);
            border-color: var(--primary);
            font-size: 0;
            box-shadow: 0 0 0 3px #ffffff50;

            &.completed {
                color: var(--primary);
                font-size: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    content: "\f00c";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    font-size: 14px;
                }
            }
        }

    }
}

.incred_stepper {
    .nav-link {

        &+#RFS-LabelContainer {
            span {
                word-spacing: unset !important;
            }
        }
    }
}

.search_btn {
    width: 40px;
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1D253B;

    i {
        display: block;
    }

    span {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .search_btn {
        width: 100%;

        i {
            display: none;
        }

        span {
            display: block;
        }
    }
}

.clear_filter_btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myleads-footer {
    position: absolute;
    bottom: 45px;
    margin-bottom: 20px;
    padding-left: 2%;
}

@media screen and (max-width: 768px) {
    .myleads-footer {
        margin-bottom: 20px;
        padding-left: 2%;
    }
}

.leads_toggle_container {
    button {
        // width: 50%;
        height: auto;
        border: 0;
        border-color: none;
        border-radius: 0;
        // border-bottom: 1px solid #EAEAEA;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        max-width: max-content;
        font-weight: 400;
        margin-left: 10px;
        margin-right: 10px;

        &:hover {
            // border-color: #EAEAEA !important;
            border-color: none;
        }

        &.active {
            border-bottom: 3px solid var(--primary);
            color: #292929;
            font-weight: 600;
        }
    }
}

.otpVerify {
    width: min-content;
    text-align: center;
    height: 300px;
    padding: 15px;
}

.my-leads-card {
    min-height: 475px;
}

.select-group {
    background-color: #234C9B;
    border: #234C9B;
    position: absolute;
    right: 5px;
    top: 0;
}

.calendar-frame {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: text;
    color: #aaa;
}

.form_sidenote {
    color: #818181;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
}

.router-button {
    color: rgba(0, 0, 0, 0.67);
    font-family: "Poppins", sans-serif;
}

.router-button:hover {
    color: #234C9B;
}

.title-card-body {
    margin-right: 30px;
    margin-bottom: 6px;
    padding-top: 25px;
    // width: 100%;

    .vertical-line {
        border-left: 1px solid #6084ca;
        height: 60px;
    }

    .card-values {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: #C5CFE5;
    }

    .stretch-card {
        width: 340px;
    }
}

.header_box {
    .card {
        width: fit-content;
        background-color: #234C9B;
    }

    .card-body {
        width: max-content;
    }
}

.loader {
    position: fixed;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    align-items: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.modal-body {
    .para_heading {
        font-size: 1rem;
        color: var(--secondary);
        font-weight: bold;
    }

    @media screen and (max-width: 767px) {
        .inner-ul {
            padding-left: 0 !important;
        }
    }

    .inner-ul {
        padding-left: 2rem;
        padding-bottom: 1rem;
    }
}

@media screen and (max-width: 760px) {
    .sidebar-content {
        min-width: 220px;
    }
}

@media (min-width: 767px) and (max-width: 1023px) {
    .hide-title {
        display: none;
    }
}

#openRouteModel {
    .modal-body {
        height: 500px;
        overflow: scroll;
    }

    .modal-header {
        padding-bottom: 15px;
        border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    }
}

.offline_card {
    padding: 12px;
    border-radius: 10px;
    // max-width: 300px;
    border: 1px solid #DBE3F9;
    background: #F1F6FF;
    box-shadow: 4px 4px 4px 0px rgba(226, 226, 226, 0.25);
    cursor: pointer;

    img {
        width: clamp(60px, 4vw, 80px);
        height: clamp(60px, 4vw, 80px);
        border-radius: 100px;
        // border: 3px solid #fff;
        transition: .9s;
        object-fit: cover;
    }

    // &:hover img {
    //     box-shadow: 0 0 0 3px var(--primary);
    // }
    .title {
        color: #5A6782;
        font-family: 'Poppins';
        font-size: 17px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        font-family: Poppins;

    }
}

.offline_card:hover {
    background: #FFF;

    .title {
        color: #234C9B;
    }
}

.consent_otp_modal {
    &.modal-lg {
        .modal-content {
            max-width: 100% !important;
        }
    }

    .modal-content {
        border-radius: 14px;
        background: #FFF;
        max-width: 420px;
        margin: 0 auto;

        h2 {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: clamp(16px, 2vw, 24px);
            font-style: normal;
            font-weight: 600;
            // line-height: 16px;
        }
    }

    .otp_container {
        &.small {
            input {
                margin: 4px !important;
                width: 40px !important;
                height: 40px !important;
            }
        }

        input {
            border-radius: 6px;
            border: 1px solid #C3C3C3;
            background: #FFF;
            width: 45px !important;
            height: 45px !important;

        }
    }

    .result_box {
        img.icon {
            width: 60px;
            height: auto;
            margin: 0;
            margin-bottom: 2rem;
        }

        p {
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 500;
        }

        h3 {
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 500;
        }


    }

    .spinner_box {
        img {
            width: 80px;
            margin: 0;
            margin-bottom: 2rem;
        }

        h2 {
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 500;
        }
    }

    .sec_title {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #263041;
    }

    ol {
        li {
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 500;
            color: #263041;
            list-style: decimal !important;
            padding-bottom: .5rem;
        }
    }

    .kyc_container {
        label {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.global_loader_container {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.834) url("https://media4.giphy.com/media/5AtXMjjrTMwvK/giphy.gif?cid=ecf05e470yjhyfuwmewqt2awonxcubxe5fissgekzna0ioyq&ep=v1_gifs_related&rid=giphy.gif&ct=s") center no-repeat;
    background-size: 100px;
    z-index: 999999;
    right: 0;
    top: 0;
}

.user-icon {
    position: absolute;
    right: 12px;
    top: 12px;
}

.password_eye_btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border: 0;
    background: transparent;
    cursor: pointer;

    i {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

#changepasswordModal {
    .modal-dialog {
        // margin-left: 35%;
        align-items: center;
        justify-content: center;
    }

    .modal-title {
        margin-top: 10% !important;
    }

    .modal-header {
        border: 0;
    }

    .modal-content {
        width: 360px;
    }

    .change-password-section {
        button {
            width: 286px;
            margin-left: 10px;
        }
    }
}

.apply-now-btn {
    background-color: #234C9B;
}

.table-primary-text {
    color: #3363C1;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.table_text_sm {
    font-size: 12px;
}

.formdata-cancel-btn {
    border-radius: 5px;
    background-color: #F0F3FA;
    color: #666C7E;
    border-color: #D6DBE7;

    &:hover,
    &:active {
        background-color: #F0F3FA !important;
        color: #666C7E !important;
        border-color: #D6DBE7 !important;
    }
}

.digi-vertical-line {
    border-left: 1px solid #D8D8D8;
    background-color: #000;
    height: 40px;
}

#sendPayoutModal {
    .modal-header {
        height: 0px;

        button {
            display: flex;
            align-items: center;
            padding: 0 !important;
        }
    }
}

.bottom_container {
    position: fixed;
    display: block;
    width: 100%;
    height: 12%;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
}

.sidebar-title {
    font-size: 14px;
    // margin-left: 10px;
}

.menu-icon-img {
    margin-bottom: 6px;
}

.search-icon {
    cursor: pointer;
}

.clear-all {
    cursor: pointer;
    display: flex;
    justify-content: end;
    padding-right: 7%;
    padding-top: 2px;
}

.digirep-clear-all {
    padding-right: 20%;
}

.offline-clear-all {
    padding-right: 8%;
}

.nav-link.active {
    font-weight: 600;
    color: #000 !important;
}

.address_confirmation_box {
    .card_header {
        font-size: 16px;
        font-weight: 600;
        color: #263041;
    }

    .card_body {
        p {
            &.label {
                font-size: 12px;
                font-weight: 400;
                color: #6E7D9C;
                margin: 0;
                padding: 0;
                border: 0;
                border-radius: 0;
            }

            &.address,
            &.value {
                font-size: 16px;
                font-weight: 500;
                color: #3363C1;
            }
        }

        .input_box {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            color: #23314C;
            padding-left: 1rem;

            .input_box_label {
                display: flex;
                align-items: baseline;

                input {
                    padding-right: 1rem;
                    padding-bottom: 1rem;
                }

                label {
                    padding-left: 1rem;
                    margin: 0;
                }
            }
        }
    }
}

.confirm_preview_box {
    h2 {
        font-size: 16px;
        font-weight: 600;
        color: #263041;
    }

    hr {
        margin-top: 1rem;
    }
}

// SIDEBAR HOVER STYLES
.metismenu {
    .menu-icon {
        margin: 0;
        margin-right: 10px;

        .menu-icon-img,
        .nested-menu-icon-img {
            width: 20px;
            height: 20px;
            object-fit: contain;

            &.masterdata-img {
                width: 22px;
            }

            &.active {
                display: none;
            }
        }

        &.vas-icon {
            img {
                width: 26px;
                height: 26px;
            }
        }
    }

    .dropdown_ul {
        li {
            &:hover {
                .nested-menu-icon-img {
                    display: none;
                }

                .nested-menu-icon-img.active {
                    display: block;
                }
            }
        }
    }

    // hover states
    li {

        &:hover,
        &.mm-active {
            .menu-icon-img {
                display: none;
            }

            .menu-icon-img.active {
                display: block;
            }

            .has-arrow {
                i {
                    color: var(--primary) !important;
                }
            }
        }

        a {
            &.has-arrow {
                display: flex;
                align-items: center;
                justify-content: space-between;

                i {
                    font-family: 'font awesome 5 free' !important;
                    font-weight: bold !important;
                    font-size: 14px;
                    float: right;
                    color: #2C2C2C !important;

                    @include custommq($max: 1023px) {
                        display: none;
                    }
                }
            }
        }

        ul {
            &.mm-show {
                li {
                    padding-left: 1rem;

                    &.mm-active {
                        a {
                            color: var(--primary);
                            font-weight: 500;
                        }
                    }

                    a {
                        .menu-icon {
                            &.level_2 {
                                img {
                                    width: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.metrics_card_container {
    padding: 2rem;
    border-radius: 8px;
    background: #3363C1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &.blue_card {
        background: #234C9B;
    }

    @media screen and (max-width: 992px) {
        align-items: flex-start;
        justify-content: start;
        flex-direction: column;
    }

    .metrics_card {
        margin: 0;
        margin-right: 18px;
        background-color: transparent;
        width: 100%;
        height: 100%;
        max-width: 400px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &.mw-100 {
            max-width: 100px !important;
        }

        &.mw-150 {
            max-width: 150px !important;
        }

        &.mw-200 {
            max-width: 200px !important;
        }

        &:not(:last-child) {
            border-right: 2px solid #ffffff73;
        }

        @media screen and (max-width: 992px) {
            max-width: 100%;
            align-items: flex-start;
            justify-content: start;

            &:not(:last-child) {
                border-right: none !important;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                border-bottom: 2px solid #ffffff1f;
            }
        }

        .card-title {
            margin-bottom: 6px;
            color: #FFF;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
        }


        .col_left,
        .col_right {
            padding: 0;
        }

        .col_right {
            width: 100%;
        }

        .col_left {
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 60px;
                height: 60px;

                @media screen and (max-width: 600px) {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        .bottom_content {
            // max-width: 230px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            p {
                margin: 0;
                color: #C5CFE5;
                font-family: "Poppins", sans-serif;
                font-size: clamp(12px, 2vw, 14px);
                font-style: normal;
                font-weight: 400;
            }
        }
    }

    .leads_detail_select_box {
        position: absolute;
        top: 0;
        right: 0;

        .react-dropdown-select {
            background-color: transparent;
            border: 0;

            &:focus-within {
                box-shadow: none;
            }

            .react-dropdown-select-content {
                color: white;
            }

            .react-dropdown-select-dropdown-handle svg {
                fill: #ECECEC;
            }

            .react-dropdown-select-input {
                &::placeholder {
                    color: #ECECEC;
                    font-family: 'Poppins', sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
        }

    }
}

.filter_tab_container {
    background-color: white;
    padding: 1rem;
    z-index: 10;

    button {
        margin: 0;
        margin-right: 1rem;
        padding: 0.5rem;
        background: transparent;
        color: #888888;
        border-radius: 0;
        font-weight: 500;
        border-bottom: 0.1875rem solid transparent;
        border-width: 0 0 0.1875rem 0;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
        z-index: 10;
        position: relative;

        &.active {
            color: #000;
            background-color: #fff;
            border-color: var(--primary);
            border-radius: 0;
            border-width: 0 0rem 0.1875rem 0;
        }
    }
}

.filter_tab_pane {
    background-color: #fff;
}

.page-titles .breadcrumb li a {
    font-size: 14px;
    color: #3363C1;
}

.page-titles .breadcrumb .bc-title {
    font-size: 14px;
    font-weight: 500;
    color: #3363C1;
}

.form-group .col-form-label {
    width: 100%;
}

.form-group .form-control input {
    background: inherit;
}

.AppDateTimePicker.disabled,
.AppDateTimePicker.disabled input {
    opacity: 0.8;
    background-color: #e9ecef;
    pointer-events: none;
}

.form-control:disabled:hover {
    background-color: #e9ecef;
}

.card-body .custom-tab-1 .nav-link {
    margin-right: 25px;
}

.role_card_container .role_card {
    height: auto;
}

.form-group {
    &.form-title {

        label {
            color: var(--primary);
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 16px;
        }

        input,
        textarea {
            display: none;
        }
    }
}

.custom-tab-1 {
    position: relative;

    &.myearnings {
        .nav-tabs.nav {
            max-width: 500px;
        }
    }

    .nav-tabs {
        border: 0;
        z-index: 10;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        max-width: fit-content;

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: transparent;
        }

        .nav-item {
            flex: 0 0 auto;
        }
    }

    .nav-link {
        color: #888888;
        background-color: #fff;
    }
}

@media screen and (min-width: 900px) {
    .custom-tab-1 .tab-content .page_header {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 11;
    }
}


.plain-text-btn {
    border: 0;
    color: var(--primary);
    background-color: transparent;
    transition: font-weight .3s ease;

    &:disabled {
        pointer-events: none;
    }

    &:hover {
        color: var(--primary-hover) !important;
        background-color: transparent !important;
        font-weight: bold;
    }

    &:active {
        color: var(--primary-hover) !important;
        background-color: transparent !important;
    }
}

#changepasswordModal {
    #bsmodalclose {
        visibility: hidden;
    }
}

.AppForm {
    .form_title {
        color: var(--primary);
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 16px;
    }
}

.bulk_download_btn_outer {
    position: absolute;
    top: 0;
    right: 1rem;

    &.transparent_btns {
        button {
            border: none;
            color: #8A8A8A;
            font-family: "Poppins", sans-serif;
            font-size: 13px !important;
            font-weight: 400;

            &:hover {
                color: #fff;
            }
        }
    }

    .bulk_download_btn {
        border: 0;
        background: transparent;
        color: #8A8A8A;
        font-family: Poppins;
        font-size: 13px;
        font-weight: 400;
    }
}

@media screen and (max-width: 1100px) {
    .bulk_download_btn_outer {
        position: relative;
        display: flex;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 575px) {
    .bulk_download_btn_outer {
        position: relative;
        top: 0;
        right: 0;
        margin: 0;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

.floating_button {
    position: absolute;
    top: 0;
    right: 1rem;
}

.link_btn {
    border: 0;
    background: transparent;
    color: var(--primary);
    text-decoration: underline;
    text-underline-position: under;
    font-weight: 600;
}

.profile_details_container {
    .nav_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        max-width: 200px;
        margin-left: auto;
    }
}

.employee_card_container {
    .employee_card {
        .card-body {
            background-color: #3363C1;
            border: 1px solid #D6DBE7;
            border-radius: 8px;
            box-shadow: none;
            outline: 0;

            .card_header {
                display: flex;
                align-items: baseline;
                justify-content: space-between;

                .avatar_card {
                    display: flex;
                    align-items: baseline;

                    img.avatar {
                        width: 50px;
                        height: 50px;
                        border: 0;
                        background: transparent;
                        border-radius: 100px;
                    }

                    .content {
                        .title {
                            font-family: "Poppins", sans-serif;
                            font-size: 18px;
                            margin: 0;
                            color: white;
                        }

                        .desc {
                            font-family: "Poppins", sans-serif;
                            font-size: 14px;
                            color: white;
                        }
                    }
                }

                button.edit_btn {
                    border: 0;
                    background: transparent;
                    font-size: 16px;
                    color: #3363C185;
                }
            }

            .card_body {
                .content_card {
                    p {
                        font-family: "Poppins", sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFFFFFB2;

                        span {
                            margin: 0;
                            margin-right: 0.5rem;
                            color: #FFFFFFB2;
                        }
                    }
                }
            }
        }
    }

    .profile_card {
        background-color: #F8FAFF;
        border: 1px solid #D6DBE7;
        border-radius: 8px;
        box-shadow: none;
        outline: 0;
    }
}

.header-left {
    .search-area {
        height: 100%;

        .form-control,
        .input-group-text {
            background-color: #364359 !important;
            height: 100%;
        }
    }
}

.nav-header .nav-control {
    background-color: #364359 !important;
}

.nav-header {
    .tag_line {
        margin: 0;
        margin-left: 0.5rem;
        padding: 0;
        padding-left: 0.5rem;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        border: 0;
        border-left: 2px solid #fff;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
}

// TATA CAPITAL
.Onboarding-stepper {
    .outer_card {
        padding: 40px;
        box-shadow: none;
        border-radius: 4px;
    }

    .nav_container {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

.kyc_container {
    &.admin {
        border: none;
        background-color: transparent;
        margin: 0;
        padding: 0;
    }

    border: 1px solid #DFDFDF;
    background: #fff;
    margin: 0 1rem;
    padding: 2rem;

    @media screen and (max-width: 575px) {
        margin: 0;
    }

    .heading-title {
        font-family: "Poppins", sans-serif;
        font-size: 22px;
        font-weight: 600;
    }

    .offline_card {
        height: 100%;
    }

    .metrics_card {
        margin: 0;
        margin-right: 18px;
        padding: 2rem;
        width: 100%;
        border-radius: 8px;
        background: #3363C1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &:not(:last-child) {
            border-right: 2px solid #ffffff73;
        }

        @media screen and (max-width: 575px) {
            padding: 1rem;
        }

        @media screen and (max-width: 992px) {
            max-width: 100%;
            align-items: flex-start;
            justify-content: start;

            &:not(:last-child) {
                border-right: none !important;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                border-bottom: 2px solid #ffffff1f;
            }
        }

        .card-title {
            margin-bottom: 6px;
            color: #FFF;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
        }

        .col_left,
        .col_right {
            padding: 0;
        }

        .col_right {
            width: 100%;
        }

        .col_left {
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 60px;
                height: 60px;

                @media screen and (max-width: 600px) {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        .bottom_content {
            max-width: 230px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            p {
                margin: 0;
                color: #C5CFE5;
                font-family: "Poppins", sans-serif;
                font-size: clamp(12px, 2vw, 14px);
                font-style: normal;
                font-weight: 400;
            }
        }

        .white_btn {
            width: 100%;
            max-width: max-content;
            height: auto;
            background-color: #fff;
            color: #3363C1;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 500;
            text-align: center;

            &:active {
                color: #3363C1 !important;
            }
        }
    }
}

.aadhar_info_Card {
    margin-bottom: 1rem;

    ul {
        li {
            font-size: 16px;
            padding: 0.6rem 0;
            color: #000;

            span {
                color: #888888;
            }
        }
    }
}


.info_card.user_view {
    margin: 0 10px;
    margin-bottom: 30px;
    // padding: 20px;
    border-radius: 10px;
    background: #3363C1;

    .content {
        display: flex;
        flex-direction: column;
        position: relative;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        .edit_btn {
            width: 36px;
            height: 36px;
            border: 0;
            background: transparent;
            color: white;
            font-size: 16px;
            position: absolute;
            top: 10px;
            right: 10px;
            border: 1px solid transparent;
            border-radius: 8px;
            cursor: pointer;
            transition: .3s;

            &:hover {
                border: 1px solid #fff;
            }
        }

        .mini_box {
            margin: 0;
            width: 100%;
            max-width: 300px;
            display: flex;
            align-items: baseline;
            color: #FFF;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            position: relative;

            i {
                margin: 0;
                margin-right: 1rem;
                font-size: 18px;
            }

            &:not(.end)::after {
                content: "";
                display: block;
                width: 2px;
                height: 50%;
                background-color: #ffffff52;
                position: absolute;
                right: 25%;
            }
        }

        .name_card {
            padding: 20px;
            display: flex;
            align-items: center;

            .avatar_circle {
                margin: 0;
                margin-right: 1rem;
                width: 60px;
                min-width: 60px;
                height: 60px;
                background: #FEF2F3;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-weight: 600;
                color: var(--primary-dark);
            }

            .name {
                font-family: "Poppins", sans-serif;
                font-size: 18px;
                font-weight: 600;
                color: #fff;
            }
        }

        .content_list_container {
            border-top: 2px solid #FFFFFF85;
            margin: 0 2rem;
            display: flex;
            align-items: baseline;
            padding: 20px 0;

            .content_list {

                li {
                    flex: 1 1 100%;
                    color: #FFFFFFCC;
                    padding: 4px 0;
                    display: flex;

                    span {
                        &.icon {
                            margin: 0;
                            margin-right: 1rem;

                            img {
                                width: 22px;
                                height: 22px;
                                object-fit: contain;
                                object-position: center;
                            }
                        }

                        &.text {}
                    }
                }
            }
        }
    }
}

.profile_card {
    // margin: 0 10px;
    // margin-bottom: 30px;

    .card-header {

        .card_icon {
            width: 30px;
            height: 30px;
        }

        .action_box {
            button {
                border: 0;
                background: transparent;
                color: #234C9B;
                font-size: 18px;
                width: 36px;
                height: 36px;
                transition: 0.3s;

                &:hover {
                    color: var(--primary);
                }
            }

            .separator {
                color: #B09C9C66;
                background-color: #B09C9C66;
            }

            .check_icon {
                width: 28px;
                height: 28px;
                background: var(--primary);
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                border-radius: 100px;

            }
        }
    }

    .card-body {
        h5 {
            color: #5B4C4C;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }

        p {
            color: #261313;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        .text_box {
            display: flex;

            a {
                margin: 0;
                margin-left: 1rem;
                text-decoration: underline;
            }
        }

        .content_box_container {

            .content_box {
                &.bordered_title {
                    border: 1px solid #E6DFDF;
                    border-radius: 10px;
                    box-shadow: 0px 4px 54px 0px #EDE4E499;
                    padding: 14px 10px;

                    li.title span.label {
                        color: var(--primary);
                    }
                }

                li {
                    display: flex;
                    align-items: center;
                    padding-bottom: 8px;

                    span {
                        &:first-child {
                            text-align: left;
                        }

                        &:last-child {
                            color: #3363C1 !important;
                        }

                        color: #000;
                        font-family: "Poppins",
                        sans-serif;
                        font-size: 14px;
                        font-weight: 500;

                        &.label {
                            color: #717171;
                        }
                    }
                }
            }
        }

        .docs_card_container {
            [class*="col-"] {
                &:not(:last-child) {
                    .docs_card {
                        border-right: 2px solid #DBDBDB;
                    }
                }
            }

            .docs_card {
                position: relative;
                text-align: center;

                img {
                    width: 100px;
                    height: 100px;
                }

                h2.title {
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    font-weight: 500;
                }

                a {
                    text-decoration: underline;
                }

                .esign_box {
                    margin: 0;
                    margin-left: 1rem;
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;

                    p {
                        font-size: 12px;
                    }

                    button {
                        border: 0;
                        background: transparent;
                        color: var(--primary);
                        text-decoration: underline;
                        font-family: "Poppins", sans-serif;
                        font-size: 12px;
                        font-weight: 500;
                        margin: 0;
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
    .profile_card {
        .docs_card_container {
            [class*="col-"] {
                &:nth-child(2) {
                    .docs_card {
                        border-right: none !important;
                    }
                }

                &:nth-child(4) {
                    .docs_card {
                        border-right: none !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .profile_card {
        .docs_card_container {
            [class*="col-"] {
                &:nth-child(2) {
                    .docs_card {
                        border-right: none !important;
                    }
                }

                &:nth-child(4) {
                    .docs_card {
                        border-right: none !important;
                    }
                }
            }
        }
    }
}

.info_btn {
    padding: 0;
    margin: 0;
    border: 1px solid #6E7D9C;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    color: #6E7D9C;

    &:hover,
    &:focus,
    &:active {
        color: #6E7D9C !important;
        border: 1px solid #6E7D9C !important;
    }
}

.custom_ul {
    li {
        margin-bottom: 10px;
        padding: 0 0 0 30px;
        font-size: 14px;
        color: #938786;
        list-style: none;
        background-image: url("/src/images/icons/circle_check_icon.png");
        background-repeat: no-repeat;
        background-position: left 4px;
        background-size: 16px;
    }
}

.offer_list_card {
    margin: 0;
    padding: 1.5rem;
    width: 100%;
    height: 100%;
    border: 1px solid #D6DBE7;
    background-color: #F8FAFF !important;

    .card_header {
        h2 {
            color: #263041;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .card_body {
        ul {
            li {
                color: #67748D;
            }
        }

        .docs_card_container {
            [class*="col-"] {
                &:not(:last-child) {
                    .docs_card {
                        border-right: 2px solid #DBDBDB;
                    }
                }
            }

            .docs_card {
                text-align: center;

                img {
                    width: 100px;
                    height: 100px;
                }

                h2.title {
                    font-family: "Poppins", sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                }

                a {
                    font-size: 14px;
                    text-decoration: underline;
                }
            }
        }
    }
}

.bankwise_offer_card {
    &.active {}

    &.inactive {
        pointer-events: none;

        .offline_card {
            background: #ededed !important;
        }
    }

}

// 
.file_area_input_outer {

    width: 100%;
    height: 100px;
    border-radius: 4px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .file_area_input {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;

    }
}

.incred_modal {
    .incred_pan_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        text-align: start;
        padding: 1rem;

        p {
            color: #A3A3A3;

            &.label_content {
                color: #000;
                font-weight: 600;
            }
        }
    }

    &.resend_modal {
        .modal-content {
            max-width: 100% !important;
        }
    }

    p {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: #000;

        a {
            color: #3363C1;
            text-decoration: underline;
        }
    }

    button {
        max-width: 300px;
    }
}

.input_link_btn {
    float: right;
    position: relative;
    top: -8px;
    border: 0;
    background: transparent;
    color: var(--primary);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
}

.error_view {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    i {
        font-size: clamp(40px, 6vw, 80px);
        color: #3363C1;
    }

    p {
        max-width: 500px;
    }

    .btn {
        max-width: 100px;
    }
}

.notification_preview_modal {

    .modal-header {
        h2 {
            color: #3363C1;
        }

        .btn-close {
            margin: 0;
        }
    }

    .modal-body {
        min-height: 350px;
    }

    .modal-content {
        max-width: 100% !important;
    }

    p {
        margin: 0;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #343333;
    }

    .time_box {
        display: flex;
        align-items: center;
    }

    .bootom_footer_action {
        button {
            width: 100px;
        }
    }

    img {
        max-width: 300px;
        margin: 0;
        margin-bottom: 1rem;
    }
}

.duplicate_records_preview_modal {
    .table-responsive {
        height: 150px;
    }

    img {
        max-width: 50px;
    }

    .modal-content {
        max-width: 100% !important;
    }

    .bottom_box {
        button {
            max-width: 250px;
        }
    }
}