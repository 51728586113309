.Toastify {
    .Toastify__toast {
        background: var(--primary);
        border-radius: 10px;
        color: white;
        font-family: "Poppins", sans-serif;

        &-icon {
            width: 26px;

            svg {
                fill: white;
            }
        }

    }
}