.ant-pagination {
    padding-right: 1rem;

    .ant-pagination-total-text {
        font-family: "Poppins", sans-serif;
    }

    .ant-pagination-item {
        z-index: 1;
        padding: 0;
        width: 2rem;
        height: 2rem !important;
        border-radius: 0.625rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        background: rgba(255, 255, 255, 0.15);
        color: #6e6e6e;
        margin-left: 7px;
        border: 0.0625rem solid #E6E6E6;

        &-active {
            background-color: var(--primary) !important;
            color: #fff;
        }

        &:hover {
            background-color: var(--primary) !important;
            color: #fff;
            border-color: var(--primary);

            >a {
                color: #fff;
            }
        }
    }

    .nav-prev,
    .nav-next {
        padding: 0;
        width: 2rem;
        height: 2rem !important;
        border-radius: 0.625rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        background: rgba(255, 255, 255, 0.15);
        color: #6e6e6e;
        margin-left: 7px;
        border: 0.0625rem solid #E6E6E6;
    }
}

.ant-upload {
    width: 100% !important;

    .ant-btn {
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        font-weight: 400;
        color: #00000082;

        .ant-btn-icon {
            position: absolute;
            top: 0.8rem;
            right: 0;
        }

        span:not([class]) {
            position: absolute;
            top: 0.6rem;
            left: 1rem;
        }
    }
}

.ant-btn {
    width: 100% !important;
    height: 40px !important;
}